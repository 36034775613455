@import "../../assets/sass/variables";

.addClient {
  width: 100%;
  min-width: 947px;
  max-width: 947px;

  &-title {
    padding: 15px 30px;
    border-bottom: 1px solid $borderColor;
    border-radius: 3px 3px 0px 0px;
    background: $btnGradient;
    display: flex;
    justify-content: space-between;
    
    p {
      margin: 10px 0 0;
    }
    h1 {
      color: $white;
      font-size: 14px;
    }
  }

  &-content {
    padding: 30px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    &-aside {
      width: 25%;
      padding: 30px 10px 30px 30px;
      background: $tabBgColor;
      flex: 1;
      .source-list {
        max-height: calc(100vh - 270px);
        margin-bottom: 0px;
      }
    }
    &.warning {
      padding: 70px;
    }
  }
}

.edit-profile {
  .ant-modal-body {
    .addClient-title {
      padding: 15px;
      border-bottom: none;
    }
  }

  .ant-radio-group {
    margin-top: 10px;
  }

  .ant-radio-wrapper {
    color: #fff;
    margin: 0 20px 0 0;

    .ant-radio-checked .ant-radio-inner {
      border-color: $primaryColor;
    }

    .ant-radio-inner:after {
      background: $primaryColor;
    }
  }
}
// *--------select dropdown----------*
.new-role {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background: transparent;
    border: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 10px 6px;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    margin-top: 5px;
    box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: $baseColor;
  }

  .ant-select-arrow .anticon > svg {
    color: $tabdetail;
  }

  .ant-select-arrow .anticon {
    margin-top: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.5715;
  }
}

.deleteConfirmation {
  padding: 80px 70px;
  border: 0px;

  h1 {
    font-family: Font-Semibold, sans-serif;
  }

  p {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}

.patient-project {
  text-align: center;

  .info {
    color: $textPrimaryColor;
    font-size: 16px;
  }
  ul {
    margin-bottom: 0px;
    max-height: 250px;
    overflow-y: auto;

    .ant-checkbox-wrapper {
      width: 100%;
      color: $textPrimaryColor;
      padding: 10px 0;
      border-bottom: 1px solid $borderColor;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
  .ant-checkbox-checked:after {
    border-color: $primaryColor;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primaryColor;
  }
  .aiButton {
    margin: 40px 0 30px 0;
  }
}

.first-project {
  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}


.autonomizeModal {
  .pd-t-0 {
    padding-top: 0px !important;
  }
  .ant-modal-content {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .ant-modal-body {
      padding: 0px;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-modal-close {

    top: 15px;
    right: 15px;
    &-x {
      font-size: 20px;
      line-height: 40px;
      .anticon{
        color: white !important;
      }
      // &:hover {
      //   color: $purpleColor;
      // }
    }
  }

  .ant-row {
    display: block;
  }

  .ant-form-item {
    margin-bottom: 30px;
    &-label > label {
      color: $baseTextColor;
      font-size: 14px;
      font-family: Font-Medium, sans-serif;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 3px;
    color: $baseTextColor;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px;
    border: 0.5px solid #c4c4c4;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
  }

  .ant-input-focused,
  .ant-input:focus {
    border-color: $linkColor;
    box-shadow: 0 0 0 2px rgb(50 50 166 / 20%);
  }
  .ant-input:hover {
    border-color: $linkColor;
  }

  .ant-form-item:last-child {
    padding-top: 20px;
  }
  .auto-complete {
    .ant-select {
      width: 100%;
      margin-bottom: 30px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding: 10px 6px;
      height: 44px;
      border-radius: 3px;
      border: 1px solid #c4c4c4;
      margin-top: 12px;
      box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 40px;
    }
  }
  &.edit-project {
    .addClient-content {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }

  .add-new-insight {
    .ant-form-item:last-child {
      text-align: right;
    }
  }

  .deleteConfirmation {
    .aiButton {
      min-width: 121px;
      max-width: 121px;
    }
  }
  .ant-input[disabled] {
    background: $baseColor;
    opacity: 0.5;
  }
}