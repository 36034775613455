$primaryColor: #e8ae55;
$textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
$orange: #d87e14;
$red: #c2334d;
$blue: #4743db;
$green: #46d143;
$lightgray: #ededed;
$tabdetail: #1e293b;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #635f6a;
$titleColor: #301934;
$smTitleColor: #02462d;
$borderColor: #e7ecf4;
$CardBorderColor: #f1eef8;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebe9ef;
$selectRowColor: #f9fbff;
$blackColor: #0f172a;
$darkBlue: #3232a6;
$purpleColor: #8626c0;
$prussianBlueColor: #410a63;

$white: #fff;
$linkColor: #3232a6;
$tabLinkHover: rgb(50 50 166 / 69%);
$bgGradient: linear-gradient(135deg, #7904a3 0%, #270067 56.12%);
$bgGradientX: linear-gradient(180deg, #270067 48.96%, #8626c0 100%);
$bgGradientSider: linear-gradient(180deg, #018994 27.92%, #48abb9 100%);
$btnGradient: linear-gradient(90deg, #3232a6 0%, #8626c0 100%);
$bgBtnHover: #8626c0;
$loginBtnColor: #fff;
$textThemeColor: #8626c0;
$linkColor: #3232a6;
$likHoverColor: #8626c0;
$titleColor: #383048;
$btnTransparent: transparent;
$tabBgColor: #f9f7fe;
$boxShadowColor: rgba(0, 0, 0, 0.05);
$darkPurpleColor: #721ead;
$magnoliaWhite:#F9F7FC;