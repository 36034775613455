@use "../../assets/sass/variables";
.project-card {
    &-heading{
        font-family: "Font-Semibold", Arial, Sans-Serif;
        font-size: 16px;
        color: variables.$blackColor;
    }
    &-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:30px;
    }
    &-title {
        font-family: "Font-Semibold", Arial, Sans-Serif;
        font-size: 14px;
        color: variables.$linkColor;
    }
}

.wrapper {
    max-height: calc(100vh - 235px);
}

.left-wrapper {
    padding: 0px 1.25rem !important;
}