@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.ant-layout-sider-trigger {
  margin-bottom: 10px !important;
  width: auto !important;
  background: transparent !important;
  svg {
    width: 17px;
    height: 17px;
  }
}

.sidebar {
  .logo {
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    padding: 25px;
    background: variables.$bgGradientX;
  }
  
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
  }

  &-nav {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-link {
      margin: 10px 0;
      position: relative;
      color: #bdbdbe;

      &.active,
      &:hover {
        color: variables.$baseColor;
      }

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  &-footer {
    gap: 15px;
    margin-bottom: 50px;
  }
}

.rotate-icon {
  transform: rotate(180deg);
}
