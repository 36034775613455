@import "../../assets/sass/variables";

.care-plan-body {
  display: flex;
  height: 100%;
  gap: 20px;

  .pdf-holder {
    width: 55%;
    height: 496.283px;
    position: relative;
  }

  .dar-notes {
    height: auto;
    padding-left: 10px;
    white-space: pre-line;

    .heading {
      font-weight: bold;
    }
  }

  .care-plan-versions {
    .ant-table .ant-table-tbody{
      max-height: 60vh;
    }
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    gap: 20px;
  }
  
  .expand-icon {
    position: absolute;
    right: 0px;
    top: 15px;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.button-container Button {
  border-radius: 3px;
  border: 1px solid $darkBlue;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}