@mixin display-grid($display, $grid-area) {
  display: $display;
  grid-area: $grid-area;
}
@mixin display-flex($display, $justify-content, $align-item, $gap, $wrap) {
  display: $display;
  justify-content: $justify-content;
  align-items: $align-item;
  gap: $gap;
  flex-wrap: $wrap;
}

@mixin padding($p-top, $p-right, $p-bottom, $p-left) {
  padding: $p-top $p-right $p-bottom $p-left;
}

@mixin space-y($value) {
  &:not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc($value - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
}

@mixin space-x($value) {
  &:not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc($value - var(--tw-space-x-reverse)));
  }
}

@mixin font_size_color(
  $font: null,
  $color: null,
  $font_weight: null,
  $line_height: null,
  $font_family: null
) {
  @if $font != null {
    font-size: $font;
  }
  @if $color != null {
    color: $color;
  }
  @if $font_weight != null {
    font-weight: $font_weight;
  }
  @if $line_height != null {
    line-height: $line_height;
  }
  @if $font_family != null {
    font-family: $font_family;
  }
}
@mixin text_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
