.member-profile-container {
    padding: 20px;
    width: 100%;

    .custom-divider {
        width: 100%;
        margin: 10px 0px;
    }

    .member-title {
        font-size: 16px;
        font-weight: 500;
    }

    .member-profile-heading {
        margin-bottom: 6px
    }

    .member-flex {
        color: var(--geneis, #475569);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;

        .member-profile-heading {
            margin-bottom: 0;
        }    
    }

    .member-profile-value {
        color: black;
        font-weight: 500;
    }
}