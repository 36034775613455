@import "../../assets/sass/variables";

.callSummary {
  &-buttons {
    border-radius: 6px;
    background: #F7F7F9;
    padding: 10px;
  }
}

.uploads {
  padding: 30px 0px;
}

.collapse {
  .ant-collapse-item {
    border-radius: 6px;
    border: 1px solid var(--border, #E7ECF4);
    background: var(--Color, #FFF);
    padding: 10px;

    .precall-custom-short-collapse {
      padding: 10px 0;
    }
  }

  .precall-review-lg-collapse {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .ant-collapse {
    width: 100%;
    background-color: #FFFFFF;
    border: none;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #E7ECF4;
  }

  .ant-collapse-item-active {
    border-bottom: 1px solid #d3d6db;
  }

  .ant-collapse-expand-icon {
    align-items: flex-end;
  }

  .ant-collapse-header {
    color: var(--link, $darkBlue) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    background-color: #FFFFFF;
    border-radius: 0px !important;
  }

  .ant-collapse-content {
    height: 300px;
    border-radius: 0 !important;
    border-bottom: none !important;
    border-top: 1px solid $darkBlue;
    overflow: auto;
  }
}

.table-preview {
  width: 15%;
}