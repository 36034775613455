@import "../../assets/sass/variables";

.main {
  padding: 0px !important;
  display: flex;
  height: 100%;

  .member-details {
    background-color: white;
    min-height: 50%;
    border-radius: 10px;
  }

  .member-details-contianer {
    background-color: #F5F5F6;
    position: relative;
    height: 100%;
    padding: 10px;

    &-toggle {
      position: absolute;
      top: 50%;
      transform: rotate(90deg);
      border-radius: 20px 20px 0 0;
      padding: 3px 25px;
      border: 1px solid #ededed;
      font-size: 12px;
      transition: all 250ms ease;
      cursor: pointer;
      right: -42px;
      background-color: #F5F5F6;
      border-bottom: none;

      &:hover {
        background-color: $lightgray;
      }
    }
  }

  .second-container {
    width: 100%;
    padding: 5px 25px;

    .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $darkBlue;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: $darkBlue;
    }
  }
}