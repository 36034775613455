@use "../../../assets/sass/variables";

.header {
  &.ant-layout-header {
    height: 70px;
    padding: 0 20px;
    background: variables.$baseColor;
    grid-area: header;
    line-height: normal;
    border-bottom: 1px solid variables.$borderColor;
    border-radius: 10px 0 0 0;
    margin-top: 6px;
  }

  &-dropdown {
    position: relative;

    svg {
      width: 20px;
      height: 20px;
      color: #94a3b8;

      // &:hover,
      // &:focus {
      //   color: variables.$linkColor;
      // }
    }

    &-menu {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      background: variables.$baseColor;
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 6px;
      padding: 1.25rem 1.5rem;
      gap: 1rem;
      font-weight: 400;
      line-height: 21px;
      // color: variables.$baseColor;
      top: calc(100% + 1rem);
      right: 0;
      min-width: 174px;
      transition: all 0.2s linear;
      z-index: 10;
      border: 1px solid variables.$borderColor;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

      &:before {
        content: "";
        position: absolute;
        height: 2rem;
        top: -2rem;
        width: 100%;
      }

      &-item {
        color: variables.$baseTextColor;
        gap: 10px;

        svg {
          fill: variables.$baseTextColor;
        }

        &:hover {
          color: variables.$linkColor;
        }
      }
    }
  }

  &-content-menu {
    &:hover {
      .header-dropdown {
        svg {
          color: variables.$linkColor;
        }
      }
      .header-dropdown-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-content {
    // gap: 40px;
    // margin-left: auto;

    &-profile {
      gap: 20px;

      &.flex {
        flex-wrap: nowrap;
      }

      img {
        width: 46px;
        height: 46px;
      }

      &-content {
        // text-align: left;
        padding-bottom: 10px;
        p {
          font-size: 14px;
          color: variables.$linkColor;
          // font-size: 0.875rem;
          // font-weight: 500;
        }
        span {
          font-size: 12px;
          color: #abb7c9;
          display: block;
          line-height: 1.5;
        }
      }

      // &-content span {
      //   font-size: 13px;
      //   color: variables.$text-color1;
      //   display: block;
      //   line-height: 1.2;
      //   // font-family: DmSans-Regular;
      // }
    }

    &-search {
      width: 370px;
      align-self: center;
      background: transparent;
      align-self: center;
    }

    &-notification {
      align-self: center;
      padding-top: 5px;
    }

    &-menu {
      // text-align: right;
    }
  }
}

.custom-search {
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 8px;
      height: 36px;
      background: transparent;
      border-color: #38383d;
      color: #ffffff;
      border: 1px solid #88888b !important;
      box-shadow: none !important;
      width: 100%;

      input {
        padding-left: 20px;
      }
    }

    .ant-select-selection-placeholder {
      color: #5d5d60;
      padding-left: 20px;
    }

    .ant-select-arrow {
      position: absolute;
      top: 10px;
      margin: 0;
      color: #3e3f43;
      left: 10px;
    }

    .ant-select-selection-item {
      padding-left: 20px;
      visibility: hidden;
    }
  }
}
.impersonation-bar {
  color: variables.$linkColor;
  font-size: 16px;
}
