@import "../assets/sass/variables";
.warning-icon {
  svg {
    width: 30px;
    height: 30px;
    color: $primaryColor;
  }
}
p {
  font-size: 14px;
}
