@import "../../../assets/sass/variables";

.updateCarePlan {
  width: 100%;
  min-width: 947px;
  max-width: 947px;
  &-title {
    padding: 15px 30px;
    border-bottom: 1px solid $borderColor;
    border-radius: 3px 3px 0px 0px;
    background: $btnGradient;
    display: flex;
    justify-content: space-between;

    p {
      margin: 10px 0 0;
    }
    h1 {
      color: var(--Color, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      padding-top: 10px;
      color: #60b769;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-subtitle {
      color: var(--geneis, #475569);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-button {
      margin: 20px;
    }
    &-icon {
      background: pink;
      padding: 20px;
    }
  }
}
.autonomizeModal .ant-modal-content {
  padding: 0px;
}
