@import "../../assets/sass/variables";

.precall-review {
 &__data {
  padding: 20px 0px;
  .collapse {
    &-eligibility-pcp {
      .ant-collapse-item {
        border-radius: 6px;
        border: 1px solid $borderColor;
        background: $textPrimaryColor;
        padding: 10px;

        .label {
          color: black;
          font-weight: 500;
        }

        .precall-custom-short-collapse {
          padding: 5px 0;
        }
      }

      .ant-collapse {
        width: 100% !important;
      }
    }

    .precall-review-lg-collapse {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
    }
  
    .ant-collapse {
      width: 100%;
      background-color: $textPrimaryColor;
      border: none;
    }
  
    .ant-collapse-item {
      border-bottom: 1px solid $borderColor;
    }
    
    .ant-collapse-item-active  {
      border-bottom: 1px solid #d3d6db;
    }
  
    .ant-collapse-header {
      color: $darkBlue !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      background-color: #FFFFFF;
      border-radius: 0px !important;
      padding: 8px;
    }

    .ant-collapse-content {
      border-radius: 0 !important;
      border-bottom: none !important;
      border-top: 1px solid $darkBlue
    }

    .ant-collapse-content-box{
      padding: 8px;
    }
  }

  &__summary {
  .heading {
    color: #1E293B;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 15px;
    line-height: 20px; 
    border-bottom: 1px solid #E7ECF4;
  }
  .content {
      font-size: 12px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 55vh;
      overflow: auto;

      .single-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: var(--title, #1E293B);
        font-weight: 400;
        white-space: pre-line;
        margin-top: 10px;

        strong {
          color: #475569;
          font-weight: 700;
          border-top: 1px solid #E7ECF4;
          padding-top: 10px;
          margin: 10px 0;
          display: block;
          
          &:nth-child(1) {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
          }
        }
        
      }
    }
  }
 }

 .precall-tabs {
  &-buttons {
    border-radius: 6px;
    background: #F7F7F9;
    padding: 10px;
  }
}
 
 &__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  gap: 10px;
 }
}