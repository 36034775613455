.App {
  text-align: center;
}

.light-mode {
  --font-color: #101212;
  --body-bg-color: #ffffff;
}
.dark-mode {
  --font-color: #cccccc;
  --body-bg-color: #101212;
}
