@use "../sass/variables";
@import "../sass/mixins";

.flex {
  display: flex;
  flex-wrap: wrap;
}
.cursor-pointer {
  cursor: pointer;
}
.font-Semibold {
  font-family: "font-Semibold";
}
.grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.no-wrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

.aie {
  align-items: flex-end;
}

.jcc {
  justify-content: center;
}

.jce {
  justify-content: end;
}

.asc {
  align-self: center;
}

.jcsb {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.h-full {
  height: 100%;
}

.m-0 {
  margin: 0;
}

.m-t {
  margin-top: 30px;
}
.m-b {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0;
}

.f100 {
  flex-basis: 100%;
}
.opacity-0 {
  opacity: 0;
}
.fauto {
  flex: auto;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.gp-10 {
  gap: 10px;
}

.gp-40 {
  gap: 40px;
}

.gp {
  gap: 20px;
}

.italic {
  margin-top: 2px;
  font-size: 12px;
  font-style: italic;
}

.bd-tp {
  color: rgba($baseColor, 0.7);
  font-family: Font-Regular;
  padding-top: 20px;
  border-top: 1px solid rgba($borderColor, 0.2);
}

@include btn(fill);
@include btn(outline);
@include tab-btn(tab-active);
@include tab-btn(tab-inactive);

.custom-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error {
  font-size: 12px;
  color: #ff4d4f;
  margin-top: 6px;
}

/*switch*/
.ant-switch {
  background: $borderColor;
  &-checked {
    background: variables.$linkColor;
    &:focus {
      box-shadow: none;
    }
  }
  &-handle:before {
    background: $baseColor;
  }
}

/* Scroll*/

.scrollBar {
  max-height: 400px;
  overflow-y: auto;
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec;
  // border: 0.3px solid #919191;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ececec;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: $green;
}

.ant-progress-inner {
  background-color: $borderColor;
}

.ant-table-filter-dropdown {
  display: none;
}
.ant-table-filter-trigger:hover {
  color: #bfbfbf;
  background: transparent;
}
.ant-table-filter-trigger {
  cursor: default;
}
.ant-notification-notice {
  border-radius: 6px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
  line-height: 1.3;
  margin-left: 40px;
}
.ant-notification-notice-close {
  right: 10px;
  top: 21px;
  color: white;
}

.ant-empty-normal {
  height: 40px;
}

.ant-empty-normal .ant-empty-image {
  display: none;
}

.ant-empty-description {
  display: none;
}

// cursor pointers class
.cursor-pointer {
  cursor: pointer;
}
.cursor-disable {
  cursor: not-allowed;
}

.normal-font-size {
  font-size: 12px;
  font-family: Font-Regular;
}
.border-bottom {
  border-bottom: 2px solid variables.$CardBorderColor;
}

.p-0 {
  padding: 0px;
}

.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px 20px;
}
.p-20 {
  padding: 20px;
}
.pb-0 {
  padding-bottom: 0;
}
.p-block {
  padding: 10px 0;
}
.table-heading {
  @include font_size_color(14px, variables.$tabdetail, null, null);
  font-family: Font-Medium;
}
.text_ellipsis {
  @include text_ellipsis;
}
.basic-detail-content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
}

.white-box-bg {
  padding: 24px;
  border-radius: 3px;
  border: 1px solid $CardBorderColor;
  background: $white;
  box-shadow: 0px 4px 15px 0px $boxShadowColor;
}

.tab-content-scroll {
  overflow: auto;
  max-height: calc(100vh - 210px);
}

.link-color {
  color: $linkColor;
}

.fw-700 {
  font-weight: 700;
}

.mt-auto {
  margin-top: auto;
}

.fz-16 {
  font-size: 16px;
}

.ant-tooltip {
    font-size: 12px;
}

.table-action {
  width: 10%;
  text-align: left;
}