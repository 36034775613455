@use "../../assets/sass/variables";

.back-icon {
  a {
    color: variables.$baseTextColor;
  }
  svg {
    padding: 1px 0 0 0;
    margin-right: 4px;
  }
}
